import React from "react"
// import { Link } from "gatsby"

import App from "../components/App"
import '../style/main.scss';

const IndexPage = () => (
  <>
    <App />
  </>
    /* <Link to="/page-2/">Go to page 2</Link> <br /> */
)

export default IndexPage
